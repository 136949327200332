import React, { useContext, useEffect, useRef, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Icon from '../icon/Icon';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import FormGroup from '../bootstrap/forms/FormGroup';
import Checks from '../bootstrap/forms/Checks';
import { useReactToPrint } from 'react-to-print';
import Button from '../bootstrap/Button';
import { useFormik } from 'formik';
import Input from '../bootstrap/forms/Input';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../contexts/authContext';
import useSortableData from '../../hooks/useSortableData';
import { dataPagination } from '../PaginationButtons';
import Collapse from '../bootstrap/Collapse';
import OpenCardComponent from '../../common/components/OpenCardComponents';
import { convertNumToMonth, getColumnsForDataTable, getCurrentDateAndTime, getPaymentType, onlyAllowNumber } from '../../services/common.service';
import NoDataMsg from '../../common/components/NoDataMsg';
import { addUpdateStaffSalaryPaidDetails, excelUpload, getStaffListForPayRun, getStaffPayRunList } from '../../services/salary.service';
import { toasts } from '../../services/toast.service';
import Select from '../bootstrap/forms/Select';
import { showLoader } from '../../services/loader.services';
import { getLicenseKey } from '../../services/application.settings';
import AlertService from '../../services/AlertService';
import useMinimizeAside from '../../hooks/useMinimizeAside';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../layout/SubHeader/SubHeader';
import { downloadExcel } from '../../services/export.service';
import classNames from 'classnames';
import useDarkMode from '../../hooks/useDarkMode';
import { priceFormat } from '../../helpers/helpers';

const SalaryDetails = () => {

	useMinimizeAside();
	useEffect(() => {
		getPaymentTypeList();
		getColumnsForTable('getStaffSalaryDetailsExcel', 'get');
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(100);
	const { items, requestSort, getClassNamesFor } = useSortableData([]);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);

	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [isLoader, setIsLoader] = useState(false);
	const [dataSuccess, setDataSuccess] = useState(false);
	const [salaryDetails, setSalaryDetails] = useState<any>([]);
	const [dummyData, setDummyData] = useState<any>([]);
	const [noDataMsg, setNoDataMsg] = useState('');
	const [month, setMonth] = useState('');
	const [year, setYear] = useState('');
	const componentRef = useRef(null);
	const navigate = useNavigate();
	const { themeStatus, darkModeStatus } = useDarkMode();

	const [salaryDetailsArray, setSalaryDetailsArray] = useState<any>([])
	const [data, setData] = useState<any>([])
	const [paymentTypeData, setPaymentTypeData] = useState<any>([])

	const [paymentTypeId, setPaymentTypeId] = useState<any>('')
	const [paymentStatusId, setPaymentStatusId] = useState<any>('')
	const [totalSalaryAmount, setTotalSalaryAmount] = useState<any>(0)
	const [bulkOrList, setBulkOrList] = useState<any>('')
	const [excelData, setExcelData] = useState<any>()

	const [totalStaffSalaryAmount, setTotalStaffSalaryAmount] = useState<any>(0);
	const [totalPaidSalaryAmount, setTotalPaidSalaryAmount] = useState<any>(0);
	const [totalUpPaidSalaryAmount, setTotalUpPaidSalaryAmount] = useState<any>(0);
	const [totalStaffCount, setTotalStaffCount] = useState<any>(0);
	const [totalWorkingDays, setTotalWorkingDays] = useState<any>(0);

	// For Alert
	const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
	const [isOpen, setIsOpen] = useState(false);

	const payRunForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			monthAndYear: '',
		},
		validate: (values) => {
			const errors: {
				monthAndYear?: string;
			} = {};

			if (!values.monthAndYear) {
				errors.monthAndYear = 'Required';
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => { },
	});

	const addPayRunForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			paymentTypeId: '',
			paymentStatusId: '',
			amount: '',
			chequeNumber: '',
			neftRefNumber: '',
		},
		validate: (values) => {
			const errors: {
				paymentTypeId?: string;
				paymentStatusId?: string;
				amount?: string;
				chequeNumber?: string;
				neftRefNumber?: string;
			} = {};

			if (!paymentTypeId) {
				errors.paymentTypeId = 'Required';
			}
			if (!paymentStatusId) {
				errors.paymentStatusId = 'Required';
			}
			if (!values.amount) {
				errors.amount = 'Required';
			}
			if (paymentTypeId != 1) {
				if (paymentTypeId == 2) {
					if (!values.chequeNumber) {
						errors.chequeNumber = 'Required';
					}
				}
				else if (paymentTypeId == 3) {
					if (!values.neftRefNumber) {
						errors.neftRefNumber = 'Required';
					}
				}
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => { },
	});

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },
		//validateOnChange: false,
		onSubmit: () => { },
	});

	function viewSalaryBulkDetails() {
		closeAndReset()
		setDataSuccess(false);
		setIsLoader(true);
		setBulkOrList('bulk')
		let monthAndYear = payRunForm.values.monthAndYear;
		const splitDate = monthAndYear.split('-');
		const year = splitDate[0];
		const month = splitDate[1];

		setYear(year);
		setMonth(month);

		setTimeout(() => {
			// Your code to run after 3 seconds
			setIsLoader(false);
			setIsOpenListCard(false);
			setDataSuccess(true);
			// You can also add any additional state updates or actions here
		}, 1000); //13000ms = 1 seconds
	}

	function viewSalaryDetails() {
		closeAndReset()
		setDataSuccess(false);
		setIsLoader(true);
		setBulkOrList('list')
		let monthAndYear = payRunForm.values.monthAndYear;
		const splitDate = monthAndYear.split('-');
		const year = splitDate[0];
		const month = splitDate[1];

		setYear(year);
		setMonth(month);
		getStaffSalaryList(year, month);
	}

	function getStaffSalaryList(year: any, month: any) {
		setIsLoader(true);
		getStaffPayRunList(year, month, 0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffPayRunList;
					if (data != undefined) {
						setSalaryDetails(data);
						setIsLoader(false);
						setIsOpenListCard(false);
						setDataSuccess(true);
						setTotalWorkingDays(data[0].totalWorkingDays)
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
							data[i].isChecked = false;
							data[i].paidAmount1 = 0;
						}
						setTotalStaffCount(data.length)
						let totalAmount1 = 0;
						let totalSalaryAmount1 = 0;
						let totalPending1 = 0;
						for (let data1 of data) {
							let totalSalaryAmount = Number(data1.totalSalaryAmount)
							let collectionAmount = Number(data1.paidAmount)
							let pendingAmount = Number(data1.pendingAmount)
							totalSalaryAmount1 += totalSalaryAmount;
							totalAmount1 += collectionAmount;
							totalPending1 += pendingAmount;
						}
						setTotalStaffSalaryAmount(totalSalaryAmount1);
						setTotalPaidSalaryAmount(totalAmount1);
						setTotalUpPaidSalaryAmount(totalPending1);
					} else {
						setIsLoader(false);
						setDataSuccess(false);
						setIsOpenListCard(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setIsLoader(false);
					setSalaryDetails([]);
					setDataSuccess(true);
					setIsOpenListCard(false);
					setNoDataMsg(response.data.message);
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
			},
		);
	}

	function getPaymentTypeList() {
		getPaymentType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.paymentType;
					if (data != undefined) {
						setPaymentTypeData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setPaymentTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function onSelect(e: any, salary: any) {
		const newData: any = [];
		salaryDetails.forEach((item: any) => {

			if (item.staffDetailsId == salary.staffDetailsId) {
				item.isChecked = e.target.checked
			}
		})
		newData.push(salary)
		setData(newData)

		if (salaryDetailsArray.length == 0) {
			var obj
			obj = {
				staffDetailsId: salary.staffDetailsId,
				totalSalaryAmount: salary.totalSalaryAmount,
				monthlyAmount: salary.monthlySalaryAmount,
				paidAmount: salary.pendingAmount,
				totalWorkingDays: salary.totalWorkingDays,
				totalPaidDays: salary.totalPaidDays,
				totalLeaveDays: salary.totalLeaveDays,
				totalOtWorkingTime: salary.totalOtWorkingTime,
				totalOtAmount: salary.totalOtAmount,
				totalLateCome: salary.totalLateCome,
				lateComeDeduction: salary.lateComeDeduction,
				totalLunchLateCome: salary.totalLateCome,
				lunchLateComeDeduction: salary.lunchLateComeDeduction,
				salaryAdvanceAmount: salary.salaryAdvanceAmount,
				loanAmount: salary.loanAmount,
				pfAmountDeduction: salary.getTotalPfAmount
			};
			salaryDetailsArray.push(obj);
		}
		else {
			let selectedItem: any
			salaryDetailsArray.forEach((item: any) => {
				if (item['staffDetailsId'] == salary['staffDetailsId']) {
					selectedItem = item
				}
			});
			if (selectedItem != undefined) {
				salaryDetailsArray.splice(salaryDetailsArray.indexOf(selectedItem), 1);
			} else {
				var obj
				obj = {
					staffDetailsId: salary.staffDetailsId,
					totalSalaryAmount: salary.totalSalaryAmount,
					monthlyAmount: salary.monthlySalaryAmount,
					paidAmount: salary.pendingAmount,
					totalWorkingDays: salary.totalWorkingDays,
					totalPaidDays: salary.totalPaidDays,
					totalLeaveDays: salary.totalLeaveDays,
					totalOtWorkingTime: salary.totalOtWorkingTime,
					totalOtAmount: salary.totalOtAmount,
					totalLateCome: salary.totalLateCome,
					lateComeDeduction: salary.lateComeDeduction,
					totalLunchLateCome: salary.totalLateCome,
					lunchLateComeDeduction: salary.lunchLateComeDeduction,
					salaryAdvanceAmount: salary.salaryAdvanceAmount,
					loanAmount: salary.loanAmount,
					pfAmountDeduction: salary.getTotalPfAmount
				};
				salaryDetailsArray.push(obj);
			}
		}
		getTotalAmount()
	}

	function getTotalAmount() {
		let totalAmount1 = 0;
		for (let data of salaryDetailsArray) {
			let collectionAmount = Number(data.paidAmount)
			totalAmount1 += collectionAmount;
		}
		setTotalSalaryAmount(totalAmount1);
	}

	function updateAmountValue(paidAmount: any, staffDetailsId: any) {
		const newData: any = [];
		salaryDetails.forEach((item: any) => {

			if (item['staffDetailsId'] == staffDetailsId) {
				item['paidAmount1'] = paidAmount
			}
			newData.push(item)
		})
		setData(newData)

		if (salaryDetailsArray.length == 0) {
			const obj = {
				paidAmount: paidAmount,
			};
			salaryDetailsArray.push(obj);
		} else {
			let selectedItem: any;
			salaryDetailsArray.forEach((item: any, index: any) => {
				if (item['staffDetailsId'] == staffDetailsId) {
					selectedItem = item
				}
			});
			if (selectedItem != undefined) {
				selectedItem['paidAmount'] = paidAmount
			} else {
				var obj
				obj = {

					paidAmount: paidAmount,
				};

				salaryDetailsArray.push(obj);
			}
		}
		getTotalAmount()
	}

	const paymentStatusData = [{ paymentStatusId: 1, paymentStatus: 'Full Paid' }, { paymentStatusId: 2, paymentStatus: 'Partial Paid' }, { paymentStatusId: 3, paymentStatus: 'Hold' }]

	function selectPaymentStatus(e: any) {
		setPaymentStatusId(e)
		addPayRunForm.setValues({
			paymentTypeId: addPayRunForm.values.paymentTypeId,
			paymentStatusId: addPayRunForm.values.paymentStatusId,
			amount: addPayRunForm.values.amount,
			chequeNumber: '',
			neftRefNumber: '',
		});
		if (e != 2) {
			salaryDetailsArray.forEach((item: any) => {
				item['paidAmount'] = item['pendingAmount']
			})
		}
	}

	function setStaffSalaryDetails() {
		return ({
			paidBy: userAccountId,
			licenseKey: getLicenseKey,
			year: year,
			month: month,
			dateOfSalary: getCurrentDateAndTime('date'),
			salaryDetails: salaryDetailsArray,
			paymentTypeId: paymentTypeId,
			paymentStatusId: paymentStatusId,
			amount: addPayRunForm.values.amount,
			chequeNumber: addPayRunForm.values.chequeNumber != '' ? addPayRunForm.values.chequeNumber : null,
			neftRefNumber: addPayRunForm.values.neftRefNumber != '' ? addPayRunForm.values.neftRefNumber : null
		})
	}

	function addUpdateStaffSalaryPaidDetailSubmit() {
		showLoader(true);
		if (addPayRunForm.isValid) {
			if (salaryDetailsArray.length > 0) {
				if (totalSalaryAmount == addPayRunForm.values.amount) {
					let salaryDetailsPostData = setStaffSalaryDetails()
					addUpdateStaffSalaryPaidDetails(salaryDetailsPostData,
						(response) => {
							const data = response.data;
							if (data.success == true) {
								showLoader(false);
								setAlertStatus({ message: data.message, type: "success" });
								setIsOpen(true);
								closeAndReset();
								setDataSuccess(false);
								setIsOpenListCard(true);
							}
							else if (data.success == false) {
								showLoader(false);
								setAlertStatus({ message: data.message, type: "error" });
								setIsOpen(true);
							}
							else {
								let errorCode = response.data.error[0].error_code;
								let errorDescription = response.data.error[0].error_description;
								showLoader(false)
								setIsOpen(true);
								setAlertStatus({ message: errorDescription, type: "error" });
							}
						}, (error) => {
							showLoader(false);
							setAlertStatus({ message: error, type: "error" });
							setIsOpen(true);
						}
					)
				} else if (totalSalaryAmount != addPayRunForm.values.amount) {
					showLoader(false);
					setAlertStatus({ message: 'Total Amount Not Matched!', type: "error" });
					setIsOpen(true);
				}
			} else if (salaryDetailsArray.length == 0) {
				showLoader(false);
				setAlertStatus({ message: 'Please select a staff!', type: "error" });
				setIsOpen(true);
			}
		} else if (addPayRunForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: "error" });
			setIsOpen(true);
		}
	}

	function handleFileUpload(e: any) {

		if (e.target.files && e.target.files[0]) {
			setExcelData(e.target.files[0]);
		}
	}

	function addUpdateStaffSalaryPaidDetailByExcelSubmit() {
		showLoader(true);
		if (addPayRunForm.isValid) {

			if (excelData != undefined) {
				const fileData = new FormData();
				fileData.append('excelUpload', excelData, excelData.name);
				fileData.append('licenseKey', getLicenseKey.toString());
				fileData.append('staffDetailsId', userAccountId);
				fileData.append('paymentTypeId', paymentTypeId);
				fileData.append('paymentStatusId', paymentStatusId);
				fileData.append('year', year);
				fileData.append('month', month);
				fileData.append('totalAmount', addPayRunForm.values.amount);
				fileData.append('chequeNumber', addPayRunForm.values.chequeNumber);
				fileData.append('neftRefNumber', addPayRunForm.values.neftRefNumber);
				excelUpload(fileData,
					(response) => {
						const data = response.data;
						if (data.success == true) {
							showLoader(false);
							setAlertStatus({ message: data.message, type: "success" });
							setIsOpen(true);
							closeAndReset();
							setDataSuccess(false);
							setIsOpenListCard(true);
						}
						else if (data.success == false) {
							showLoader(false);
							setAlertStatus({ message: data.message, type: "error" });
							setIsOpen(true);
						}
						else {
							let errorCode = response.data.error[0].error_code;
							let errorDescription = response.data.error[0].error_description;
							showLoader(false)
							setIsOpen(true);
							setAlertStatus({ message: errorDescription, type: "error" });
						}
					}, (error) => {
						showLoader(false);
						setAlertStatus({ message: error, type: "error" });
						setIsOpen(true);
					}
				)
			} else {
				showLoader(false);
				setAlertStatus({ message: 'Please select a file!', type: "error" });
				setIsOpen(true);
			}
		} else if (addPayRunForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: "error" });
			setIsOpen(true);
		}
	}

	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(userTypeId, apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData)
						let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
						setColumnVisibilityData(columnVisibilityData)
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function closeAndReset() {
		addPayRunForm.resetForm();
		setPaymentTypeId('');
		setPaymentStatusId('');
		setSalaryDetailsArray([]);
		setSalaryDetails([]);
	}

	// Print
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (

		<PageWrapper title='Salary Details List'>
			<Page container='fluid'>
				<Collapse isOpen={isOpenListCard}>
					<Card
						stretch
						data-tour='list'
						tag='form'
						noValidate
						onSubmit={payRunForm.handleSubmit}>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info'>
								<CardTitle tag='div' className='h5'>
									Salary Details
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row g-4'>
								<div className='col-3'>
									<FormGroup id='monthAndYear' label='Month' isFloating>
										<Input
											onChange={payRunForm.handleChange}
											value={payRunForm.values.monthAndYear}
											onBlur={payRunForm.handleBlur}
											isValid={payRunForm.isValid}
											isTouched={payRunForm.touched.monthAndYear}
											invalidFeedback={payRunForm.errors.monthAndYear}
											type='month'
										/>
									</FormGroup>
								</div>

								<div className='col-6'>
									<Button
										className='mt-2'
										icon='ArrowDownward'
										color='primary'
										onClick={viewSalaryDetails}
										isDisable={payRunForm.values.monthAndYear != '' ? false : true}>
										View
									</Button>&nbsp;&nbsp;&nbsp;

									<Button
										className='mt-2'
										icon='CloudDownload'
										color='success'
										onClick={viewSalaryBulkDetails}
										isDisable={payRunForm.values.monthAndYear != '' ? false : true}>
										Bulk Upload
									</Button>
								</div>
							</div>
						</CardBody>
					</Card>
				</Collapse>

				<OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

				{dataSuccess ? (
					<>
						<form noValidate onSubmit={addPayRunForm.handleSubmit}>
							{bulkOrList == "list" ?
								<>
									<Card stretch ref={componentRef}>
										<CardHeader borderSize={1}>
											<CardLabel icon='List' iconColor='info' className='col-lg-6'>
												<CardTitle tag='div' className='h5'>
													<span className='text-primary'>{convertNumToMonth(month)} - {year}</span> Salary Details - <span className='text-danger'>{totalWorkingDays}</span> Days
												</CardTitle>
											</CardLabel>
										</CardHeader>

										<CardBody className='table-responsive'>
											<div className='row g-4'>
												<div className='col-xl-3'>
													<div
														className={classNames(
															'd-flex align-items-center rounded-2 p-3',
															{
																'bg-l10-secondary': !darkModeStatus,
																'bg-lo25-secondary': darkModeStatus,
															},
														)}>
														<div className='flex-shrink-0'>
															<Icon icon='Group' size='3x' color='secondary' />
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='fw-bold fs-5 mb-0'>{totalStaffCount}</div>
															<div className='text-muted mt-n1 truncate-line-1'>
																Total Staff
															</div>
														</div>
													</div>
												</div>
												<div className='col-xl-3'>
													<div
														className={classNames(
															'd-flex align-items-center rounded-2 p-3',
															{
																'bg-l10-success': !darkModeStatus,
																'bg-lo25-success': darkModeStatus,
															},
														)}>
														<div className='flex-shrink-0'>
															<Icon icon='Checklist' size='3x' color='success' />
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='fw-bold fs-5 mb-0'>{priceFormat(totalStaffSalaryAmount)}</div>
															<div className='text-muted mt-n1 truncate-line-1'>
																Total Salary
															</div>
														</div>
													</div>
												</div>
												<div className='col-xl-3'>
													<div
														className={classNames(
															'd-flex align-items-center rounded-2 p-3',
															{
																'bg-l10-primary': !darkModeStatus,
																'bg-lo25-primary': darkModeStatus,
															},
														)}>
														<div className='flex-shrink-0'>
															<Icon
																icon='Money'
																size='3x'
																color='primary'
															/>
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='fw-bold fs-5 mb-0'> {priceFormat(totalPaidSalaryAmount)}</div>
															<div className='text-muted mt-n1 truncate-line-1'>
																Total Paid Amount
															</div>
														</div>
													</div>
												</div>
												<div className='col-xl-3'>
													<div
														className={classNames(
															'd-flex align-items-center rounded-2 p-3',
															{
																'bg-l10-danger': !darkModeStatus,
																'bg-lo25-danger': darkModeStatus,
															},
														)}>
														<div className='flex-shrink-0'>
															<Icon icon='Report' size='3x' color='danger' />
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='fw-bold fs-5 mb-0'>{priceFormat(totalUpPaidSalaryAmount)}</div>
															<div className='text-muted mt-n1 truncate-line-1'>
																Total Un Paid Amount
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className="row mt-3">
												<div className='col-3'>
													<FormGroup
														id='paymentStatus'
														label='Payment Status'
														isFloating>
														<Select
															ariaLabel='Payment Status'
															onChange={(e: any) => selectPaymentStatus(e.target.value)}
															value={paymentStatusId}
															isValid={addPayRunForm.isValid}
															onBlur={addPayRunForm.handleBlur}
															isTouched={addPayRunForm.touched.paymentStatusId}
															invalidFeedback={addPayRunForm.errors.paymentStatusId}
															list={paymentStatusData.map((data: any) => (
																{ value: data.paymentStatusId, text: data.paymentStatus }
															))}
														/>
													</FormGroup>
												</div>

												<div className='col-3'>
													<FormGroup id="paymentTypeId" label="Payment Type" isFloating>
														<Select
															ariaLabel='Payment Type'
															onChange={(e: any) => setPaymentTypeId(e.target.value)}
															value={paymentTypeId}
															isValid={addPayRunForm.isValid}
															onBlur={addPayRunForm.handleBlur}
															isTouched={addPayRunForm.touched.paymentTypeId}
															invalidFeedback={addPayRunForm.errors.paymentTypeId}
															list={paymentTypeData.map((data: any) => (
																{ value: data.paymentTypeId, text: data.paymentType }
															))}
														/>
													</FormGroup>
												</div>
												{paymentTypeId != '' ?
													<div className='col-3'>
														<FormGroup id='amount' label='Total Amount' isFloating>
															<Input
																onChange={addPayRunForm.handleChange}
																value={addPayRunForm.values.amount}
																onBlur={addPayRunForm.handleBlur}
																isValid={addPayRunForm.isValid}
																isTouched={addPayRunForm.touched.amount}
																invalidFeedback={addPayRunForm.errors.amount}
															/>
														</FormGroup>
													</div> : null}

												{paymentTypeId == 2 ?
													<div className='col-3'>
														<FormGroup id='chequeNumber' label='cheque Number' isFloating>
															<Input
																onChange={addPayRunForm.handleChange}
																value={addPayRunForm.values.chequeNumber}
																onBlur={addPayRunForm.handleBlur}
																isValid={addPayRunForm.isValid}
																isTouched={addPayRunForm.touched.chequeNumber}
																invalidFeedback={addPayRunForm.errors.chequeNumber}
															/>
														</FormGroup>
													</div> : paymentTypeId == 3 ?
														<div className='col-3'>
															<FormGroup id='neftRefNumber' label='Neft Ref Number' isFloating>
																<Input
																	onChange={addPayRunForm.handleChange}
																	value={addPayRunForm.values.neftRefNumber}
																	onBlur={addPayRunForm.handleBlur}
																	isValid={addPayRunForm.isValid}
																	isTouched={addPayRunForm.touched.neftRefNumber}
																	invalidFeedback={addPayRunForm.errors.neftRefNumber}
																/>
															</FormGroup>
														</div> : null}
											</div>

											<table className='table table-modern table-hover mt-3'>
												<thead>
													<tr className='table-primary'>
														<td className='text-decoration-underline'>
															<strong>#</strong>
														</td>
														<td className='text-decoration-underline'>
															<strong>S.No</strong>
														</td>
														<td className='text-decoration-underline'>
															<strong>Staff Name</strong>
														</td>

														<td className='text-center text-decoration-underline'>
															<strong>Monthly <br />Salary</strong>
														</td>
														<td className='text-center text-decoration-underline'>
															<strong>
																Paid<br /> Days
															</strong>
														</td>
														<td className='text-center text-decoration-underline'>
															<strong>LOP  <br /> Amount</strong>
														</td>

														<td className='text-center text-decoration-underline'>
															<strong>PF  <br /> Amount</strong>
														</td>
														{/* <td className='text-center text-decoration-underline'>
															<strong>OT <br /> (Timing)</strong>
														</td> */}
														{/* <td className='text-center text-decoration-underline'>
															<strong>Late Come <br /> (Timing)</strong>
														</td>
														<td className='text-center text-decoration-underline'>
															<strong>Lunch Late Come <br /> (Timing)</strong>
														</td> */}
														<td className='text-center text-decoration-underline'>
															<strong>Salary<br /> Advance</strong>
														</td>
														{/* <td className='text-center text-decoration-underline'>
															<strong>Loan</strong>
														</td> */}
														<td className='text-center text-decoration-underline'>
															<strong>Total <br />Salary</strong>
														</td>
														<td className='text-center text-decoration-underline'>
															<strong>Paid<br /> Amount</strong>
														</td>
														<td className='text-center text-decoration-underline'>
															<strong>Pending<br /> Amount</strong>
														</td>
														<td className='text-center text-decoration-underline'>
															<strong>Status</strong>
														</td>
													</tr>
												</thead>
												<tbody>
													{salaryDetails != '' ? (
														<>
															{salaryDetails.map(
																(salary: any, index: any) => (
																	<tr key={index}>
																		<td>
																			{salary.paymentStatusId == 1 ? <Icon
																				size='lg'
																				color='success'
																				icon='CheckBox'
																			/> :
																				<Checks
																					className='border border-primary'
																					onChange={(e) => onSelect(e, salary)}
																					checked={salary.isChecked}
																					disabled={paymentStatusId != '' && paymentTypeId != '' ? false : true}
																				/>
																			}
																		</td>
																		<td>{salary.sno}</td>
																		<td>{salary.staffName} <br /> <span className='text-center text-info'>{salary.empNumber}</span> - <span className='text-center text-info'>{salary.designationName}</span> </td>

																		<td className='text-center'><span className='fw-bold'>{salary.monthlySalaryAmount}</span></td>
																		<td className='text-center'>{salary.totalPaidDays}</td>
																		<td className='text-center text-danger'>{salary.getLopAmount}</td>
																		<td className='text-center text-danger'>{salary.getTotalPfAmount}</td>
																		{/* <td className='text-center'><span className='text-success'>{salary.totalOtAmount}</span> <br />{salary.totalOtWorkingTimeForView != '' && salary.totalOtWorkingTimeForView != null ? <>(<span className='text-info'>{salary.totalOtWorkingTimeForView}</span>) </> : null}</td> */}

																		{/* <td className='text-center'><span className='text-danger'>{salary.lateComeDeduction}</span> <br />{salary.totalLateComeForView != '' && salary.totalLateComeForView != null ? <>(<span className='text-info'>{salary.totalLateComeForView}</span>)</> : null}</td> */}

																		{/* <td className='text-center'><span className='text-danger'>{salary.lunchLateComeDeduction}</span> <br />{salary.totalLunchLateComeForView != '' && salary.totalLunchLateComeForView != null ? <>(<span className='text-info'>{salary.totalLunchLateComeForView}</span>)</> : null}</td> */}

																		<td className='text-center text-danger'>{salary.salaryAdvanceAmount}</td>
																		{/* <td className='text-center text-danger'>{salary.loanAmount}</td> */}
																		<td className='text-center text-success fw-bold text-decoration-underline' style={{ cursor: 'pointer' }} onClick={() => window.open(`../salary/payslip/${payRunForm.values.monthAndYear}/${salary.staffDetailsId}`)}>{salary.totalSalaryAmount}</td>
																		<td className='text-center text-success fw-bold'>{salary.paidAmount}</td>
																		<td className='text-center text-danger fw-bold'>{salary.pendingAmount}</td>
																		{paymentStatusId == '' ?
																			<>
																				{salary.paymentStatusId == 0 ?
																					<td className='text-center text-danger fw-bold'>{salary.paymentStatus}</td> :
																					salary.paymentStatusId == 1 ?
																						<td className='text-center'><span className='text-success fw-bold'>{salary.paymentStatus} </span>{salary.paymentTypes != '' ? <span className='h6'><br />({salary.paymentTypes})</span> : null}
																						</td>
																						: salary.paymentStatusId == 2 ?
																							<td className='text-center text-danger fw-bold'>{salary.paymentStatus}</td> :
																							<td className='text-center text-danger fw-bold'>{salary.paymentStatus}</td>}
																			</> : paymentStatusId == 1 ? <td className='text-center text-danger fw-bold'>{salary.pendingAmount}</td> : paymentStatusId == 2 ? <>{salary.paymentStatusId == 1 ? <td className='text-center text-success fw-bold'>{salary.paymentStatus}</td> : <td scope='col'>
																				<div className="col-12" style={{ width: '82px' }}>
																					<FormGroup id='remarks'>
																						<Input placeholder="" value={salary.paidAmount1} disabled={salary.isChecked == true ? false : true} onInput={(e: any) => updateAmountValue(e.target.value, salary.staffDetailsId)} onKeyDown={(e: any) => onlyAllowNumber(e)} />
																					</FormGroup>
																				</div>
																			</td>} </> : <td className='text-center text-danger fw-bold'>0</td>}
																	</tr>
																)
															)}
														</>
													) : (
														<NoDataMsg columnsCount={12} msg={noDataMsg} />
													)}
												</tbody>
											</table>
										</CardBody>
									</Card>
									<SubHeader>
										<SubHeaderLeft><></></SubHeaderLeft>
										<SubHeaderRight>
											<SubheaderSeparator />
											<Button color='primary' type='submit' icon='Save' isLight onClick={() => addUpdateStaffSalaryPaidDetailSubmit()}>
												Submit
											</Button>
										</SubHeaderRight>
									</SubHeader>
								</> :
								<>
									<Card stretch ref={componentRef}>
										<CardHeader borderSize={1}>
											<CardLabel icon='List' iconColor='info' className='col-lg-6'>
												<CardTitle tag='div' className='h5'>
													{convertNumToMonth(month)} - {year} Salary Details Bulk Upload
												</CardTitle>
											</CardLabel>
										</CardHeader>

										<CardBody className='table-responsive'>
											<div className="row g-4">
												<div className='col-3'>
													<FormGroup
														id='paymentStatus'
														label='Payment Status'
														isFloating>
														<Select
															ariaLabel='Payment Status'
															onChange={(e: any) => selectPaymentStatus(e.target.value)}
															value={paymentStatusId}
															isValid={addPayRunForm.isValid}
															onBlur={addPayRunForm.handleBlur}
															isTouched={addPayRunForm.touched.paymentStatusId}
															invalidFeedback={addPayRunForm.errors.paymentStatusId}
															list={paymentStatusData.map((data: any) => (
																{ value: data.paymentStatusId, text: data.paymentStatus }
															))}
														/>
													</FormGroup>
												</div>

												<div className='col-3'>
													<FormGroup id="paymentTypeId" label="Payment Type" isFloating>
														<Select
															ariaLabel='Payment Type'
															onChange={(e: any) => setPaymentTypeId(e.target.value)}
															value={paymentTypeId}
															isValid={addPayRunForm.isValid}
															onBlur={addPayRunForm.handleBlur}
															isTouched={addPayRunForm.touched.paymentTypeId}
															invalidFeedback={addPayRunForm.errors.paymentTypeId}
															list={paymentTypeData.map((data: any) => (
																{ value: data.paymentTypeId, text: data.paymentType }
															))}
														/>
													</FormGroup>
												</div>
												{paymentTypeId != '' ?
													<div className='col-3'>
														<FormGroup id='amount' label='Total Amount' isFloating>
															<Input
																onChange={addPayRunForm.handleChange}
																value={addPayRunForm.values.amount}
																onBlur={addPayRunForm.handleBlur}
																isValid={addPayRunForm.isValid}
																isTouched={addPayRunForm.touched.amount}
																invalidFeedback={addPayRunForm.errors.amount}
															/>
														</FormGroup>
													</div> : null}

												{paymentTypeId == 2 ?
													<div className='col-3'>
														<FormGroup id='chequeNumber' label='cheque Number' isFloating>
															<Input
																onChange={addPayRunForm.handleChange}
																value={addPayRunForm.values.chequeNumber}
																onBlur={addPayRunForm.handleBlur}
																isValid={addPayRunForm.isValid}
																isTouched={addPayRunForm.touched.chequeNumber}
																invalidFeedback={addPayRunForm.errors.chequeNumber}
															/>
														</FormGroup>
													</div> : paymentTypeId == 3 ?
														<div className='col-3'>
															<FormGroup id='neftRefNumber' label='Neft Ref Number' isFloating>
																<Input
																	onChange={addPayRunForm.handleChange}
																	value={addPayRunForm.values.neftRefNumber}
																	onBlur={addPayRunForm.handleBlur}
																	isValid={addPayRunForm.isValid}
																	isTouched={addPayRunForm.touched.neftRefNumber}
																	invalidFeedback={addPayRunForm.errors.neftRefNumber}
																/>
															</FormGroup>
														</div> : null}

												<div className='col-12'>
													<div className='col-6'>
														<Input
															type='file'
															id='fileInput'
															autoComplete='photo'
															ariaLabel='Upload image file'
															onChange={(e: any) => { handleFileUpload(e) }}
														/>
													</div>
												</div>
												<div className='col-6 d-flex justify-content-start'>
													<Button
														isLight
														color='success'
														icon='PictureAsPdf'
														onClick={() =>
															downloadExcel(
																`Staff_Salary`,
																dummyData,
																columnVisibilityData,
															)
														}
													>Download Sample
													</Button>
												</div>
												<div className='col-6 d-flex justify-content-end'>
													<Button icon="Save" color="info" type="submit" onClick={() => addUpdateStaffSalaryPaidDetailByExcelSubmit()}>
														Save
													</Button>
												</div>
											</div>
											{/* <div className="col-md-lg-12 d-flex justify-content-end">
												<Button icon="Save" color="info" type="submit" onClick={() => addUpdateStaffSalaryPaidDetailByExcelSubmit()}>
													Save
												</Button>
											</div> */}
										</CardBody>
									</Card>
								</>}
						</form>
						<br />
						<br />
						<br />
					</>
				) : null}
			</Page>

			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
		</PageWrapper>
	);
};

export default SalaryDetails;